* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  color: black;
}

.root {
  min-width: 100%;
  min-height: 150px;
}


.contacts {
  display: flex;
  flex-direction: row;
}

.site {
  justify-content: center;
  background-color: rgb(189, 191, 195);
  margin: auto;
}

.header {
  display: flex;
  background-color: rgb(189, 191, 195);
  height: 130px;
  margin: auto;
  justify-content: left;
  text-align: left;
}

.logo {
  display: flex;
  justify-content: left;
  text-align: left;
  float: left;
}

.logo2 {
  margin: auto;
  justify-content: left;
}

.contactTitles {
  font-size: 40px;
  padding-bottom: 70px;
  font-weight: bold;
}

.body {
  justify-content: center;
  margin: auto;
  height: 100%;
  width: 80%;
  background-color: rgb(238, 238, 238);

}

.textbox1 {
  margin: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 1000px;
  max-width: 100%;
  text-align: center;

}


.picMain {
  display: flex;
  margin: auto;
  justify-content: center;
  padding-top: 150px;
}

.imageM {
  display: flex;
  width: 1300px;
  height: 500px;
}

.imageList {
  display: flex;
  margin: auto;
  justify-content: center;
  max-width: 100%;
}

.images {
  display: flex;
  width: 763px;
  height: 550px;
}

.textbox2 {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
  padding-top: 100px;
}

.contacts {
  flex-direction: row;
}


.contactForm {
  justify-content: center;
  display: flex;
  max-width: 100%;
  max-height: 100%;
  padding-top: 75px;
  flex-wrap: wrap;
}

.contactInfo {
  background-color: rgb(189, 191, 195);
  width: 400px;
  margin: auto;
  line-height: 70px;
  font-size: 1vw;
  width: 55%;
  max-width: 100%;
  padding-top: 150px;
  padding-bottom: 150px;
}

@media screen and (max-width: 800px) {

  .logo {
    width: 100%;
    max-width: 100%;
  }

  .logo2 {
    width: 100%;
    max-width: 100%;
  }

  .contacts {
    flex-direction: column;
    font-size: 3vw;
  }

  .contactInfo {
    width: max-content;
    width: 80%;
  }

  .contactTitles {
    padding-bottom: 40px;
    font-size: 2vh;
  }

  .carousel {
    height: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
    padding-right: 0px;
  }

}